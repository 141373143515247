import { PopupButton } from "react-calendly";
import './Services.scss'
import { PackageCard } from '../../component/PackageCard/PackageCard'
import roadmapImage from './img/roadmap-image.svg'

const Services: React.FunctionComponent = () => {
  return (
    <>
      <h1>Services we provide</h1>
      <p>
        Struggling for quality improvements? We are ready to provide end-to-end software testing to support your Software Development Life Cycle(SDLC), reduce defects, compare with your competitors,
        and save your business from costly bugs. Scroll down to find out the types of testing we do and how we provide value to your software development lifecycle.
      </p>
      <div>
        <h1>Service package options</h1>
        <div className="package-box">
          <PackageCard title="STARTUP OPTIMA">
            <div>
              <p>✔️ Testing strategy & plan</p>
              <p>✔️ Quality processes schema</p>
              <p>✔️ CI infrastructure development</p>
              <p>✔️ Workflow improvements</p>
              <p>✔️ Release management</p>
              <p>✔️ Test management system</p>
            </div>
            <PopupButton
              styles={{ alignSelf: 'center' }}
              className='standard-button'
              url='https://calendly.com/bugsurfers/service-package-options'
              text="Get now"
              prefill={{
                customAnswers: {
                  a1: ['Service package STARTUP OPTIMA',
                    '✔️ Testing strategy & plan',
                    '✔️ Quality processes schema',
                    '✔️ CI infrastructure development',
                    '✔️ Workflow improvements',
                    '✔️ Release management',
                    '✔️ Test management system'
                  ].join('\n'),
                  a2: 'Service package STARTUP OPTIMA'
                },
                date: new Date(Date.now() + 86400000)
              }}
              rootElement={document.getElementById('root') as HTMLElement}
            />
          </PackageCard>
          <PackageCard title="ESTABLISHED PROJECT">
            <p>✔️ Product analysis</p>
            <p>✔️ Finding and fixing bottlenecks</p>
            <p>✔️ Maintaining + monitoring</p>
            <p>✔️ Development of testing infrastructure</p>
            <PopupButton
              styles={{ alignSelf: 'center' }}
              className='standard-button'
              url='https://calendly.com/bugsurfers/service-package-options'
              text="Get now"
              prefill={{
                customAnswers: {
                  a1: ['Service package ESTABLISHED PROJECT',
                    '✔️ Product analysis',
                    '✔️ Finding and fixing bottlenecks',
                    '✔️ Maintaining + monitoring',
                    '✔️ Development of testing infrastructure'
                  ].join('\n'),
                  a2: 'Service package ESTABLISHED PROJECT'
                },
                date: new Date(Date.now() + 86400000)
              }}
              rootElement={document.getElementById('root') as HTMLElement}
            />
          </PackageCard>
          <PackageCard title="VENDOR + INTERNAL QA">
            <p>Suitable in the case:</p>
            <p>✔️ Lack of internal staff</p>
            <p>✔️ Temporary phase</p>
            <p>✔️ New project with experimental tech stack</p>
            <PopupButton
              styles={{ alignSelf: 'center' }}
              className='standard-button'
              url='https://calendly.com/bugsurfers/service-package-options'
              text="Get now"
              prefill={{
                customAnswers: {
                  a1: ['Service package VENDOR + INTERNAL QA',
                    '✔️ Lack of internal staff',
                    '✔️ Temporary phase',
                    '✔️ New project with experimental tech stack'
                  ].join('\n'),
                  a2: 'Service package VENDOR + INTERNAL QA'
                },
                date: new Date(Date.now() + 86400000)
              }}
              rootElement={document.getElementById('root') as HTMLElement}
            />
          </PackageCard>
          <PackageCard title="LEGO CONSTRUCTION">
            <p>✔️ The client combines any type of testing in accordance with current needs in a specific period</p>
            <PopupButton
              styles={{ alignSelf: 'center' }}
              className='standard-button'
              url='https://calendly.com/bugsurfers/service-package-options'
              text="Get now"
              prefill={{
                customAnswers: {
                  a1: ['Service package LEGO CONSTRUCTION',
                    '✔️ The client combines any type of testing in accordance with current needs in a specific period'
                  ].join('\n'),
                  a2: 'Service package LEGO CONSTRUCTION'
                },
                date: new Date(Date.now() + 86400000)
              }}
              rootElement={document.getElementById('root') as HTMLElement}
            />
          </PackageCard>
          <PackageCard title="RELEASE TEST RUN">
            <p>✔️ The QA team is only involved in the release preparation phases according to predefined cycles</p>
            <PopupButton
              styles={{ alignSelf: 'center' }}
              className='standard-button'
              url='https://calendly.com/bugsurfers/service-package-options'
              text="Get now"
              prefill={{
                customAnswers: {
                  a1: ['Service package RELEASE TEST RUN',
                    '✔️ The QA team is only involved in the release preparation phases according to predefined cycles'
                  ].join('\n'),
                  a2: 'Service package RELEASE TEST RUN'
                },
                date: new Date(Date.now() + 86400000)
              }}
              rootElement={document.getElementById('root') as HTMLElement}
            />
          </PackageCard>
          <PackageCard title="TURNKEY PROJECT">
            <p>✔️ The QA and DevOps team builds or updates the entire test infrastructure with no additional support</p>
            <PopupButton
              styles={{ alignSelf: 'center' }}
              className='standard-button'
              url='https://calendly.com/bugsurfers/service-package-options'
              text="Get now"
              prefill={{
                customAnswers: {
                  a1: ['Service package TURNKEY PROJECT',
                    '✔️ The QA and DevOps team builds or updates the entire test infrastructure with no additional support'
                  ].join('\n'),
                  a2: 'Service package TURNKEY PROJECT'
                },
                date: new Date(Date.now() + 86400000)
              }}
              rootElement={document.getElementById('root') as HTMLElement}
            />
          </PackageCard>
        </div>
        <h1 className='roadmap-title'>This is our roadmap for onboarding</h1>
        <h2 className='roadmap-work-process'>Work process</h2>
        <div className='roadmap-box'>
          <img className='roadmap-planning' src={roadmapImage} alt="Roadmap" />
          <div className='roadmap-planning-steps'>
            <button>Define goals</button>
            <button>QA Audit</button>
            <button>Presenting an improvement list</button>
            <button>Implementing</button>
            <button>Support</button>
          </div>
        </div>
      </div >
    </>
  );
};

export { Services };
