import React from 'react';
import './BugSurfersApplication.css';

import { BrowserRouter, Route, Routes } from 'react-router-dom';

import { LayoutComponent } from './page/layout/Layout';
import { NotFoundPage } from './page/NotFound';
import { Contacts } from './page/Contacts';
import { AboutUs } from './page/About us/AboutUs';
import { Services } from './page/Services/Services';
import { HomePage } from './page/Home/Home';

function BugSurfersApplication() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<LayoutComponent />}>
          <Route index element={<HomePage />} />
          <Route path="services" element={<Services />} />
          <Route path="about-us" element={<AboutUs />} />
          <Route path="contacts" element={<Contacts />} />
          <Route path="*" element={<NotFoundPage />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default BugSurfersApplication;
