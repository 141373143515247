import React from 'react';
import './StandardButton.scss';
interface Props {
    title: string;
    routeChange: () => void;
}

const StandardButton: React.FunctionComponent<Props> = ({ title, routeChange }) => {
    return (
        <>
            <button className='standard-button' onClick={routeChange}>{title}</button>
        </>
    );
};

export { StandardButton };
