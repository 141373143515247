import React from 'react';
import './PackageCard.scss';

interface Props {
    title: string;
}

const PackageCard: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ title, children }) => {
    return (
        <>
            <div className='card'>
                <h3 className='title'>{title}</h3>
                <div className='card-content'>
                    <div className='sub-title'>{children}</div>
                </div>
            </div>
        </>
    );
};

export { PackageCard };
