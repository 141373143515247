import React from 'react';
import './Header.scss';
import introductionImage from './img/introduction-image.svg';
import playImage from './img/play-button.png';
import { StandardButton } from '../StandardButton/StandardButton';
import { useNavigate } from "react-router-dom";

const Header: React.FunctionComponent = () => {
    const navigate = useNavigate();
    return (
        <>
            <div className="introduction">
                <div className='introduction-content'>
                    <div className='introduction-text'>
                        <h1 className='introduction main-text'>QA & DevOps TEAM</h1>
                        <h2 className='introduction second-text'>AT YOUR SERVICE</h2>
                        <p className='introduction'>We are team with passionate QA & DevOps engineers who can make your product successfull and business more profitable</p>
                        <p className='introduction'>We are committed to meeting your company's needs</p>
                    </div>
                    <div className='buttons'>
                        <button className='button'>
                            <img className='play-button' src={playImage} alt="" />Watch video</button>
                        <StandardButton title="Get more" routeChange={() => navigate("/about-us")} />
                    </div>
                    <img src={introductionImage} className="image" alt="BugSurfers" />
                </div>
                <div className="wave-animation">
                    <div className="wave-animation-inner bg-top">
                        <div className="wave wave-top" style={{ backgroundImage: "url('https://front-end-noobs.com/jecko/img/wave-top.png')" }}></div>
                    </div>
                    <div className="wave-animation-inner bg-middle">
                        <div className="wave wave-middle" style={{ backgroundImage: "url('https://front-end-noobs.com/jecko/img/wave-mid.png')" }}></div>
                    </div>
                    <div className="wave-animation-inner bg-bottom">
                        <div className="wave wave-bottom" style={{ backgroundImage: "url('https://front-end-noobs.com/jecko/img/wave-bot.png')" }}></div>
                    </div>
                </div>
            </div>
        </>
    );
};

export { Header };
