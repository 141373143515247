import pageNotFound from '../page/layout/img/page-not-found.svg';

const NotFoundPage: React.FunctionComponent = () => {
  return (
    <>
      <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
        <img width={220} height={220} src={pageNotFound} alt="Page not found" />
        <p>Page is not found</p>
      </div>
    </>
  );
};

export { NotFoundPage };
