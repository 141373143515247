import { InlineWidget } from "react-calendly";

const Contacts: React.FunctionComponent = () => {
  return (
    <>
      <h1>Contact us</h1>
      <p>Schedule a call with our representative to describe the request, get detailed information about our collaboration, and get first suggestions on how we can help you.</p>
      <InlineWidget url="https://calendly.com/bugsurfers/service-package-options"
        styles={{
          minWidth: "320px", height: '800px'
        }}
        prefill={{
          customAnswers: {
            a1: ['Contact me to discuss collaboration.'].join('\n'),
            a2: 'Contact me'
          },
          date: new Date(Date.now() + 86400000)
        }}
      />
    </>
  );
};

export { Contacts };
