import React from 'react';
import './AboutUs.scss'
import matureImage from './img/mature-image.svg'
import expensesImage from './img/expenses-image.svg'

const AboutUs: React.FunctionComponent = () => {
  return (
    <>
      <div>
        <h1>Bug Surfers are your easily-managed software testing department for reaching your success on the market</h1>
        <h2>Why choose Us</h2>
        <p>We provide strong decisions & maximal effort in quality assurance to software companies helping them create perfect products and build excellent software development processes</p>
        <div className='get-service'>
          <h1>What you get with us</h1>
          <p>By working with us, you don't only get all the needed testing activities covered <br /> You get a fully-fledged QA department with a clear understanding of what and when to do to get high quality</p>
        </div>
        <div className='qa_process'>
          <h4>Set up a mature <br /> QA process</h4>
          <p>From our experience, the main problem of software problem lies in how the process of delivery and development is organized(Software Development Life Cycle)
            If you allow to improve your processes and set up a proper testing activities. This will ssgain in fewer failures, faster development, and quality products.
          </p>
          <img src={matureImage} alt="" />
        </div>
        <div className='expenses'>
          <h4>Reduce administrative <br /> expenses</h4>
          <p>The cost of hiring a QA-team in-house is higher compared to employing our experts. We take care of all administrative expenses including taxes, competitors investigation, work stations, software, and office space. You pay only for the actual software testing result, leaving all your overheads to us.
          </p>
          <img src={expensesImage} alt="" />
        </div>
        <div className='case-study-box'>
          <h1>Case study</h1>
          <div className='box'>
            <h2>Problem</h2>
            <p>Our client didn't have any QA engineers during the first 3 years since starting the development phase and as a result: <br /> ▪️ rather fragile application <br /> ▪️ a huge amount of retesting directed to a dramatically long delivery process <br /> ▪️ releases with a delay <br /> ▪️ unpleasant user experience <br /> ▪️ failure to meet the client's expectations</p>
          </div>
          <div className='box'>
            <h2>Decision</h2>
            <p>After defining the most problematic areas in the development process together with refinement problems & planning of release phases we created a Test plan & strategy in order to deliver a completely another level of quality and fulfill end-user expectations.</p>
          </div>
          <div className='box'>
            <h2>Result</h2>
            <p>According to the test strategy were provided next improvements for development culture:
              <br /> ▪️ with autotests (backend & UI) were preventively blocked most of the major and critical bugs (2000+ bugs)
              <br /> ▪️ scheduled release phases(50+ phases) with a clear roadmap were handled in strong synchronization with a development & management team
              <br /> ▪️ improvements allowed to have a clear picture for planning an achievable roadmap for Product development in order to have the leading position on the market by the end of 2024</p>
          </div>
        </div>
      </div>
    </>
  );

};

export { AboutUs };
