import React from 'react';
import { NavLink } from 'react-router-dom';
import './NavigationBar.scss';

const NavigationBar: React.FunctionComponent = () => {
  return (
    <nav>
      <NavLink to="/">Our vision</NavLink>
      <NavLink to="/services">Services</NavLink>
      <NavLink to="/about-us">About us</NavLink>
      <NavLink to="/contacts">Contacts</NavLink>
    </nav>
  );
};

export { NavigationBar };
