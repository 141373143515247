// import ReactPlayer from 'react-player/youtube'
import { useNavigate } from "react-router-dom";
import { Header } from "../../component/Header/Header"
import { StandardButton } from '../../component/StandardButton/StandardButton';
import "./Home.scss"

import devOpsImage from './img/dev-ops.png';
import qaServiceImg from './img/qa-service-scope.png';
import marketingImage from './img/marketing.png';
import proposalsImage from './img/proposals.png';
import devOpsServiceImage from './img/dev-ops-service.svg';
import qaServiceImage from './img/qa-service.svg';
import hireTeamImage from './img/hire-team.svg';
import manageImage from './img/manage-services.svg';
import qaConsultingImage from './img/qa-consulting.svg';
import userTestingImage from './img/user-testing.svg';
// import ceoImage from './img/ceo-photo.png';
import whyIcon from './img/why-image.png';

const HomePage: React.FunctionComponent = () => {
  const navigate = useNavigate();
  return (
    <>
      <Header />
      <div className="content">
        <h3>We Bring Quality To Your Project</h3>
        <p className="sub-title">
          We could work in different companies without taking care of many things that occur when running this business, but there is a strong desire to create a new unique synergy in the field of quality assurance services
        </p>
        <div className="quality">
          <div className="quality-services">
            <div className="boxed">
              <img src={devOpsImage} className="quality-image" alt="BugSurfers" />
              DevOps
            </div>
            <div className="boxed boxed-with-margin">
              <img src={qaServiceImg} className="quality-image" alt="BugSurfers" />
              QA service scope
            </div>
            <div className="boxed">
              <img src={marketingImage} className="quality-image" alt="BugSurfers" />
              Marketing analysis of competitive products
            </div>
            <div className="boxed boxed-with-margin">
              <img src={proposalsImage} className="quality-image" alt="BugSurfers" />
              Proposals on product improvements
            </div>
          </div>
          <p className="quality-text">
            As a rule, in-house testers just perform their work according to the list of requirements and this is perfectly acceptable, but we have the ambition and common sense to create a unique offer
            in the market.
          </p>
          <div className='question'>
            <p>
              Why?
            </p>
            <img src={whyIcon} alt="why-icon" />
          </div>
          <p className='quality-focus-text'>
            Very often during development, there is a gap in the understanding of own product, competitors' products, and what at the end user really needs, so we decided to offer a
            new approach so that our cooperation would be a real blessing for customers - the best price/quality ratio to reach success.
          </p>
        </div>
        <div className="services">
          <h3>Services we provide</h3>
          <div className="quality-two">
            <div className="quality-services1">
              <div className="boxed-service">
                <img src={devOpsServiceImage} className="service-image" alt="BugSurfers" />
                DevOps Services
              </div>
              <div className="boxed-service">
                <img src={qaServiceImage} className="service-image" alt="BugSurfers" />
                QA Outsourcing Services
              </div>
              <div className="boxed-service">
                <img src={hireTeamImage} className="service-image" alt="BugSurfers" />
                Hire QA Team
              </div>
              <div className="boxed-service">
                <img src={manageImage} className="service-image" alt="BugSurfers" />
                Manage Testing Services
              </div>
              <div className="boxed-service">
                <img src={qaConsultingImage} className="service-image" alt="BugSurfers" />
                QA Audit and Consulting Services
              </div>
              <div className="boxed-service">
                <img src={userTestingImage} className="service-image" alt="BugSurfers" />
                User Acceptance Testing (UAT) Services
              </div>
            </div>
            <StandardButton title="Get more about services" routeChange={() => navigate("/services")} />
          </div>

        </div>
        <div className="about">
          <div className="about-info">
            <h3>About us</h3>
            <p>
              BugSurfers: Your Trusted Partner for Reliable Software Testing. Our experts ensure bug-free software with comprehensive testing services. From functional to performance testing, we have got
              you covered. Contact us today for efficient and cost-effective solutions.
            </p>
          </div>
          {/* <div className="about-content">
            <div className="video-presentation">
              <ReactPlayer className="react-player" url='https://www.youtube.com/watch?v=SteeJD7WARU' width='100%'
                height='100%' />
            </div>
            <div className="about-ceo">
              <img src={ceoImage} alt="CEO" />
              <h3>Hi, I'm Yurii <br /> Voina, BugSurfers CEO</h3>
              <p>Have more questions? <br /> Let's schedule a call.</p>
              <StandardButton title="Ask more" routeChange={() => navigate("/contacts")} />
            </div>
          </div> */}
        </div>

        {/* <h3>Our activities outside of work</h3>
        <p className="sub-title">Another of our MOTTOs is supporting Ukrainian people with disabilities, where we provide education and full support to return to a fulfilling life and we have good results already. :)</p> */}
      </div>
    </>
  );
};

export { HomePage };
