import React from 'react';
import { useLocation } from 'react-router-dom';
import { Outlet } from 'react-router-dom';
import { NavigationBar } from '../../component/NavigationBar/NavigationBar';
import { Footer } from '../../component/Footer/Footer';
import linkedin from './img/linkedin.svg';
import { Logo } from '../../component/Logo/Logo';
import './Layout.scss';

const LayoutComponent: React.FunctionComponent = () => {
  const location = useLocation();
  const className = location.pathname === '/' ? 'header-home' : 'header';
  return (
    <>
      <header className={className}>
        <Logo />
        <NavigationBar />
        <a href="https://www.linkedin.com/company/bugsurfers/" target="_blank" rel="noopener noreferrer">
          <img src={linkedin} className="linkedin" alt="Linkedin" />
        </a>
      </header>
      <main className="see">
        <Outlet />
      </main>
      <Footer />
    </>
  );
};

export { LayoutComponent };
