import React from 'react';
import logo from './img/logo.svg';

const Logo: React.FunctionComponent = () => {
  return (
    <>
      <img src={logo} className="logo" alt="BugSurfers" />
    </>
  );
};

export { Logo };
