import React from 'react';
import { useNavigate } from "react-router-dom";
import { PopupButton } from "react-calendly";
import './Footer.scss';
import { Logo } from '../../component/Logo/Logo';
import { StandardButton } from '../StandardButton/StandardButton';

const Footer: React.FunctionComponent = () => {
  const navigate = useNavigate();
  return (
    <>
      <footer>
        <div className='footer-info'>
          <Logo />
          <p className='footer-text'>Maximizing Your Potential: Experience the Unique Synergy of Our Comprehensive QA Service, DevOps, and Marketing Analysis - All in One Place!</p>
        </div>
        <div id='menu' className='footer-menu'>
          <h1 className='footer-menu-title'>Menu</h1>
          <div className='hr-center'>
            <hr />
          </div>
          <p className='footer-link' onClick={() => navigate("/")} >Our vision</p>
          <p className='footer-link' onClick={() => navigate("/services")}>Services</p>
          <p className='footer-link' onClick={() => navigate("/about-us")}>About us</p>
          <p className='footer-link' onClick={() => navigate("/contacts")}>Contacts</p>
        </div>
        <div id='services' className='footer-menu'>
          <h1 className='footer-menu-title'>Services</h1>
          <div className='hr-center'>
            <hr />
          </div>
          <p className='footer-link'>QA Outsourcing Services</p>
          <p className='footer-link'>DevOps Services</p>
          <p className='footer-link'>QA Audit and Consulting Services</p>
          <p className='footer-link'>Managed Testing Services</p>
          <p className='footer-link'>Hire QA Team</p>
          <p className='footer-link'>User Acceptance Testing (UAT) Services</p>
        </div>
        <div id='contact-us' className='footer-menu'>
          <h1 className='footer-menu-title'>Contact us</h1>
          <div id='contact-us-line' className='hr-center'>
            <hr />
          </div>
          <div className='contact-buttons'>
            <PopupButton
              className='standard-button'
              url='https://calendly.com/bugsurfers/service-package-options'
              rootElement={document.getElementById('root') as HTMLElement}
              text="Click here to schedule!"
              prefill={{
                customAnswers: {
                  a1: ['Contact me to discuss collaboration.'].join('\n'),
                  a2: 'Contact me'
                },
                date: new Date(Date.now() + 86400000)
              }}
            />
            <StandardButton title='Our LinkedIn' routeChange={() => window.open('https://www.linkedin.com/company/bugsurfers/', '_blank', 'noopener noreferrer')} />
          </div>
        </div>

        <div id='copyright-line' className='hr-center'>
          {/* <hr /> */}
        </div>
        <span className='copyright'>© 2015–2023 «BugSurfers»</span>
      </footer >
    </>
  );
};

export { Footer };
